export const SLOT_EXHIBITOR_SHARE_CONTACT_FRAGMENT = `
  fragment slotExhibitorShareContactFragment on Exhibitor {
    uid
    slots: _slots(filter: { code: "send_information_feature" }) {
      uid
      code
      quantity
      strategy
      type
    }
  }
`;
